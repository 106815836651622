import React from "react";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { BillingContextProvider } from "component-library";
import {
  CheckoutAccessTypes,
  CheckoutFlow,
} from "component-library/src/components/Organisms/Billing/types";
import { changeSubscription } from "services/api/BillingApi/BillingApi";
import useAddOnCheckoutSteps from "../hooks/useAddOnCheckoutPages";
import { useGetBillingContextDefaultProps } from "../hooks/useGetBillingContextDefaultProps";
import { useHistory } from "react-router-dom";
import applicationMap from "services/applicationMap";

export default function ChangeWrapper() {
  const history = useHistory();

  const defaultProps = useGetBillingContextDefaultProps(
    CheckoutAccessTypes.RenewalReactivation // this will probably be removed as part of SL-7453
  );
  const { Component, setAddOnCheckoutStep, addOnCheckoutStep } =
    useAddOnCheckoutSteps();

  const handleGoBack = () => {
    if (addOnCheckoutStep) return setAddOnCheckoutStep(0);

    history.goBack();
  };

  const handleGoNext = () => {
    if (!addOnCheckoutStep) setAddOnCheckoutStep(1);
  };

  const postCheckoutCallback = async (
    data: any,
    trackingData: Record<string, unknown>
  ) => {
    await changeSubscription({ planId: data.planId });

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.ChangeSubscription,
      memberTier: data?.planTypeFilter,
      ...trackingData,
    });
  };

  return (
    <BillingContextProvider
      {...defaultProps}
      checkoutFlow={CheckoutFlow.ChangePlan}
      goBack={handleGoBack}
      goNext={handleGoNext}
      postCheckoutCallback={postCheckoutCallback}
      enableAddOnsSelection={false}
      navigateToCompletePage={() =>
        history.push(applicationMap.routes.settingsMySubscription())
      }
    >
      <Component />
    </BillingContextProvider>
  );
}
