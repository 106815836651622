export const componentLibrary = {
  "Organisms.StickyCTABar.nextButton": "next-button",
  "Organisms.StickyCTABar.prevButton": "prev-button",
  "Organisms.StickyCTABar.cancelButton": "cancel-button",
  "Organisms.FilterControl.filterDrawer": "FilterDrawer",
  "Organisms.Billing.SuccessPage.backButton": "success-page-back-button",
  "Organisms.Billing.SuccessPage.heading": "success-page-heading",
  "Organisms.AddOnCheckout.SubscriptionCard.subscriptionWrapper":
    "subscription-wrapper",
  "Organisams.Billing.AddOnCheckout.AddOnPurchaseModal": "AddOnPurchaseModal",
  "Organisms.Billing.AddOnCheckout.SubscriptionCard.subscriptionButton":
    "subscription-button",
  "Organisms.Billing.AddOnCheckout.SubscriptionCard.addOnSubscriptionCard":
    "ADD-ON-subscription-card",
  "Organisms.Billing.AddOnCheckout.Header.subscriptionLink":
    "subscription-view-link",
  "Organisms.Billing.AddOnCheckout.Header.subscriptionView":
    "subscription-view",
  "Organisms.Billing.AddOnCheckout.FeatureBox.addFeatureButton":
    "add-feature-button",
  "Organisms.Billing.CardElements.country": "country",
  "Organisms.Billing.BillingAnddPaymentForm.country": "country",
  "Organisms.Billing.CardElements.cvv": "cvv",
  "Organisms.Billing.DirectDebit.confirmButton": "confirm-direct-debit-button",
  "Organisms.Billing.CDCheckout.CDSubscriptionBlock.chooseButton":
    "subscription-cta",
  "Organisms.NavigationHeader.MainNavigationItem.mainNavigationLink":
    "main-navigation-link",
  "Organisms.NavigationHeader.SubNavigationItem.subNavigationLink":
    "sub-navigation-link",
  "Organisams.InboxRow.row": "inbox-row",
  "Organisams.InboxRow.rowLink": "row-link",
  "Organisams.InboxRow.senderName": "sender-name",
  "Organisams.Search.searchInpputButton": "search-input-button",
  "Organisams.Billing.AddOnCheckout.AddOnPurchaseModal.primaryCta":
    "primary-cta",
  "Organisams.Billing.AddOnCheckout.AddOnPurchaseModal.secondaryCta":
    "secondary-cta",
  "Molecules.RecordWithActionControls.uploadRecord": "uploadRecord",
  "Molecules.DropdownInput.inputCleaner": "InputCleaner",
  "Molecules.AlertMessage.button": "ALERT_BUTTON_TEST_ID_STUB",
  "Molecules.ActionsFooter.actionsFooter": "ACTIONS_FOOTER_TEST_ID",
  "Molecules.Pagination.pageView": "pagination-",
  "Molecules.RichTextArea.richTextArea": "rich-text-area-",
  "Molecules.ActionList.dropDown": "actionListDropDown",
  "Molecules.LinksListWithTitle.siteLinks": "site-links",
  "Atoms.ButtonSplit.trigger": "BUTTON_SPLIT_TRIGGER_TEST_ID",
  "Atoms.Spinner.spinner": "Spinner",
  "Atoms.InputsCommon.validationMessage": "validation-message",
  "Atoms.InputsCommon.inputCleaner": "InputCleaner",
  "Atoms.InlineMessage.warningIcon": "WarningIcon",
  "Atoms.InlineMessage.successIcon": "SuccessIcon",
  "Atoms.FormikInputs.DatePickerFormik.calendarIcon": "CalendarIcon",
  "Atoms.FilterTag.filterTag": "FilterTag",
  "Atoms.FormikInputs.TextInputArray.input": "input-",
  "Atoms.FormikInputs.TextInputArray.addButton": "button-",
  "Atoms.FormikInputs.TextInputArray.record": "record-",
  "Atoms.FormikInputs.TextInputArray.removeButton": "remove-",
  "Atoms.FormikInputs.SelectFormik.select": "select_element ",
  "Atoms.FormikInputs.SelectFormik.list-option": "list-option",
  "Atoms.Tab.tab": "tab",
  "Atoms.Autocomplete.autocomplete": "autocomplete",
  "Organisms.NavigationHeader.NavigationHeader.myAccount": "my-account",
  "Organisms.NavigationHeader.NavigationHeader.signOut": "sign-out",
  "Organisms.NavigationHeader.NavigationHeader.spotlightIcon": "spotlight-icon",
  "Organisms.NavigationHeader.NavigationHeader.environmentIcon":
    "environment-icon",
  "Organisms.Footer.footerLinks": "footer-links",
  "Organisams.Cards.card": "cards-card",
  "Organisms.FilterControl.FilterDrawer.backButton": "filterDrawer-backButton",
  "Organisms.SimpleGrid.TableBody.Row": "tableBody-row",
  "Organisms.SimpleGnrid.TableBody.RowCell": "tableBody-row-Cell",
  "Atoms.Pill.icon": "pill-icon",
  "Atoms.IconTextLinkProps.button": "button-icon",
  "Atoms.ToggleSwitch.ToggleButton": "toggle-button",
  "Atoms.Button.Link": "button",
  "Atoms.CheckBox.Input": "checkBox-Input",
};
