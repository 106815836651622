import { useHistory, useParams } from "react-router-dom";
import { parseUrl } from "query-string";
import { useTranslation } from "i18n";
import { authorizationService } from "shared-auth";
import { ExpiryTimeUnits, setCookie } from "shared-utils";
import {
  checkoutAccessTypeMap,
  CheckoutAccessTypes,
  CheckoutFlow,
  PlanCodePeriod,
  PlanSelectorSearchParams,
} from "component-library/src/components/Organisms/Billing/types";
import applicationMap, { CheckoutType } from "services/applicationMap";
import {
  BillingUser,
  BillingUserWithoutCommon,
} from "translations/src/models/billing";
import useGetUserType from "./useGetUserType";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";

const PAYMENT_COOKIE = "PaymentStatus";

export const useGetBillingContextDefaultProps = (
  checkoutFlow: CheckoutAccessTypes
) => {
  const parsedUrl = parseUrl(window.location.search);
  const preselectedPlan =
    parsedUrl.query[PlanSelectorSearchParams.PreSelectedPlan];
  let preselectedAddOns =
    parsedUrl.query[PlanSelectorSearchParams.PreSelectedAddOns];

  if (preselectedAddOns && !Array.isArray(preselectedAddOns)) {
    preselectedAddOns = [preselectedAddOns];
  }

  const { t } = useTranslation();
  const history = useHistory();
  const { type } = useParams<{
    type: CheckoutType;
  }>();

  const userType = useGetUserType(checkoutFlow);

  const handleNavigateToCompletePage = (isDirectDebit?: boolean) => {
    history.replace(
      applicationMap.routes.checkoutComplete({ type, userType: userType }),
      {
        isDirectDebit,
      }
    );
  };

  const checkoutFlowType = checkoutFlow
    ? checkoutAccessTypeMap[checkoutFlow]
    : CheckoutFlow.Default;

  const successCallback = async () => {
    await authorizationService.signInSilent();

    setCookie(PAYMENT_COOKIE, Date.now(), {
      expiryTime: 30,
      expiryTimeUnit: ExpiryTimeUnits.MIN,
    });
  };

  return {
    billingUserType: userType as BillingUserWithoutCommon,
    goBackText: t("common:button.label.back"),
    onPaymentSuccessGoBackAction: () =>
      history.push(applicationMap.routes.index()),
    navigateToCompletePage: handleNavigateToCompletePage,
    checkoutFlow: checkoutFlowType,
    isCommonHeader: userType !== BillingUser.Performer,
    successCallback: successCallback,
    preselectedPlanOverride: preselectedPlan as PlanCodePeriod,
    preselectedAddOnsOverride: preselectedAddOns as AddOnCodes[],
  };
};
