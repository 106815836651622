import React, { useEffect } from "react";
import { useTranslation } from "i18n";
import { useHistory, useParams } from "react-router-dom";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import applicationMap, {
  CheckoutType,
  isAcceptedCheckoutType,
} from "services/applicationMap";
import { Head } from "component-library";
import { useCanAccessCheckout } from "component-library/src/components/Organisms/Billing/hooks/useCanAccessCheckout";
import { USER_ACCOUNT_APP_ROOT } from "component-library/src/components/Organisms/Billing/constants";
import CheckoutPageWrapper from "../CheckoutPageWrapper/CheckoutPageWrapper";

const Checkout = () => {
  const { type } = useParams<{ type: CheckoutType }>();
  const isSelfServeCancellationEnable = useLaunchDarklyFlags(
    FEATURE_FLAGS.SelfServeCancellation
  );
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!isAcceptedCheckoutType(type)) {
      return history.replace(applicationMap.routes.index());
    }
  }, [type]);

  // temporary to unblock testing, access check will be based on new function SL-7453
  useCanAccessCheckout(
    !(isSelfServeCancellationEnable && type === CheckoutType.Change),
    USER_ACCOUNT_APP_ROOT
  );

  return (
    <>
      <Head>
        <title>{t("common:billing.checkout.pageTitle")}</title>
      </Head>
      <CheckoutPageWrapper />
    </>
  );
};

export default Checkout;
