import React from "react";
import { RouteProps } from "react-router-dom";

import applicationMap from "services/applicationMap";

import IndexPage from "pages/IndexPage/MyAccountPage";
import Checkout from "pages/Checkout";
import CheckoutComplete from "pages/CheckoutComplete";
import AccountSettings from "pages/AccountSettings";
import LoginDetails from "pages/AccountSettings/LoginDetails/LoginDetails";
import MyDetails from "pages/AccountSettings/MyDetails";
import MySubscriptions from "pages/MySubscriptions";
import TransferProfilePage from "pages/TransferProfilePage";

export interface IRoute extends RouteProps {
  path: string;
  component: React.ComponentType<any>;
  routes?: IRoute[];
}

const routes: IRoute[] = [
  {
    path: applicationMap.routes.index(),
    exact: true,
    component: IndexPage,
  },
  {
    path: applicationMap.routes.checkout(),
    exact: true,
    component: Checkout,
  },
  {
    path: applicationMap.routes.checkoutComplete(),
    exact: true,
    component: CheckoutComplete,
  },
  {
    path: applicationMap.routes.accountSettings(),
    exact: true,
    component: AccountSettings,
  },
  {
    path: applicationMap.routes.settingsMyDetails(),
    exact: true,
    component: MyDetails,
  },
  {
    path: applicationMap.routes.settingsMyLoginDetails(),
    exact: true,
    component: LoginDetails,
  },
  {
    path: applicationMap.routes.settingsMySubscription(),
    exact: true,
    component: MySubscriptions,
  },
  {
    path: applicationMap.routes.graduatesTransferInformation(),
    exact: true,
    component: TransferProfilePage,
  },
];

export default routes;
