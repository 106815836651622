import { FC, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "i18n";
import cx from "classnames";
import _isEmpty from "lodash/isEmpty";
import { ReactComponent as ChevronIcon } from "component-library/src/images/svg/icons/arrow-right.svg";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { useDynamicWindowWidthFor } from "shared-hooks";
import { BillingUser } from "translations/src/models/billing";
import { SCREEN_WIDTH_ENUM } from "../../../../../../generics/dom-extensions";
import { Spinner } from "../../../../../Atoms/Spinner/Spinner";
import { useGetSubscriptions } from "../../../hooks/useGetSubscriptions";
import { useGetSummary } from "../../../hooks/useGetSummary";
import { BillingContext } from "../../../BillingContext";
import { CheckoutFlow, IAddOns, PlanCodePeriod } from "../../../types";
import StickyCTABar from "../../../../StickyCTABar";
import { AddOnPurchaseModal } from "../AddOnPurchaseModal";
import PlanSelector from "../PlanSelector";
import PageContent from "../PageContent";
import {
  getModalTexts,
  getPeriodText,
} from "../PlanSelector/PlanSelector.helpers";
import { BASE_PERKS } from "./constants";

const SubscriptionPage: FC = () => {
  const { t } = useTranslation();
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const isECPModalVariantFlagActive = useLaunchDarklyFlags(
    FEATURE_FLAGS.ECPModalVariant
  );
  const [isMembershipFeaturesVisible, setIsMembershipFeaturesVisible] =
    useState(false);
  const [isCheckoutSpeedbumpOpen, setIsCheckoutSpeedbumpOpen] = useState(false);
  const {
    goBack,
    goNext,
    planPeriodData,
    setPlanPeriodData,
    toggleAddOnSelected,
    preselectedPlanOverride,
    preselectedAddOnsOverride,
    billingUserType,
    enableAddOnsSelection,
    checkoutFlow,
  } = useContext(BillingContext);

  const perks =
    isMobileView && !isMembershipFeaturesVisible ? [BASE_PERKS[0]] : BASE_PERKS;

  const { isLoading: isSummaryLoading, isSuccess: isSummarySuccess } =
    useGetSummary(); // necesary to call /account because on the BE this will transfer any remaining CRM users to recurly
  const { data: subscriptions, isLoading: isSubscriptionLoading } =
    useGetSubscriptions(isSummarySuccess);

  const annualSubscription = subscriptions?.find((sub) =>
    sub.code.includes(PlanCodePeriod.Annual)
  );
  const monthlySubscription = subscriptions?.find((sub) =>
    sub.code.includes(PlanCodePeriod.Monthly)
  );
  const isYoungPerformer =
    billingUserType === BillingUser.YoungPerformer ||
    billingUserType === BillingUser.JoiningYoungPerformer;
  const showStickyCtaBar = !isECPModalVariantFlagActive || isYoungPerformer;

  const generateContinueButtonText = (defaultText?: boolean) => {
    if (!planPeriodData.selectedPeriod || defaultText || !enableAddOnsSelection)
      return t("common:button.label.continue");

    if (
      planPeriodData.selectedPeriod &&
      planPeriodData.selectedAddOns.length === 0
    )
      return t(
        "common:performer.planSelector.buttons.continueBasicSubscription"
      );

    return t("common:performer.planSelector.buttons.continuePlusAddOns");
  };

  const selectedPlan = subscriptions.find(
    (sub) =>
      planPeriodData.selectedPeriod &&
      sub?.code.includes(planPeriodData.selectedPeriod)
  );
  const selectedPeriodAddOns = selectedPlan?.addOns;

  // two useEffects for plan & addOns as they need to select options based on different conditions.
  // selects and deselects if the user changes plan period
  useEffect(() => {
    if (!preselectedAddOnsOverride || isECPModalVariantFlagActive) {
      return;
    }

    const selectedAddOns = preselectedAddOnsOverride
      ?.map((addOnCode) =>
        selectedPeriodAddOns?.find((addOn) => addOn.code === addOnCode)
      )
      .filter(Boolean) as IAddOns[];

    setPlanPeriodData({
      ...planPeriodData,
      selectedAddOns: selectedAddOns || [],
    });
  }, [selectedPeriodAddOns]);

  // only selects
  useEffect(() => {
    if (
      !preselectedPlanOverride ||
      (preselectedPlanOverride !== PlanCodePeriod.Annual &&
        preselectedPlanOverride !== PlanCodePeriod.Monthly)
    ) {
      return;
    }

    setPlanPeriodData({
      ...planPeriodData,
      selectedPeriod: preselectedPlanOverride as PlanCodePeriod,
    });
  }, []);

  const handleClearSpeedbump = () => {
    setIsCheckoutSpeedbumpOpen(false);
    goNext?.();
  };

  const isContinueButtonDisabled =
    !planPeriodData.selectedPeriod ||
    (checkoutFlow === CheckoutFlow.ChangePlan &&
      planPeriodData.selectedPeriod === preselectedPlanOverride);

  if (isSubscriptionLoading || isSummaryLoading) return <Spinner />;

  return (
    <>
      <header>
        <PageContent>
          <h1 className="py-4 text-2xl">
            {t("common:billing.subscriptionStep.pageTitle")}
          </h1>
        </PageContent>
      </header>
      <PageContent>
        <div className="pb-8">
          <p className="mb-2 text-base">
            {t("common:performer.subscriptions.perks.description")}{" "}
          </p>
          <div className="flex flex-col items-start gap-2 mb-4">
            {isMobileView && (
              <button
                type="button"
                onClick={() =>
                  setIsMembershipFeaturesVisible(!isMembershipFeaturesVisible)
                }
                className="text-color-brand-one"
              >
                <span
                  className={cx(
                    "mr-1 inline-flex justify-center items-center rounded-full text-white bg-color-brand-one w-6 h-6 transition-transform duration-300",
                    {
                      "rotate-90": isMembershipFeaturesVisible,
                    }
                  )}
                >
                  <ChevronIcon className="w-3 h-3 fill-white ml-[1px]" />
                </span>
                <span className="font-semibold">
                  {isMembershipFeaturesVisible
                    ? t("common:performer.subscriptions.perks.hideMore")
                    : t("common:performer.subscriptions.perks.showMore")}
                </span>
              </button>
            )}
            <div
              className={
                isMobileView
                  ? "flex flex-col gap-1"
                  : "flex flex-wrap gap-1 mb-4 text-base"
              }
            >
              {perks.map((perk, index) => (
                <span
                  className="flex items-center font-semibold"
                  key={perk.name}
                >
                  <perk.icon
                    className={cx(
                      "w-5 h-5 stroke-[0.2] stroke-color-neutral-one mr-1",
                      perk.iconClassName
                    )}
                  />
                  {t(perk.name)}
                  {index !== perks.length - 1 && ","}
                </span>
              ))}{" "}
              {(isMembershipFeaturesVisible || !isMobileView) && (
                <Trans
                  i18nKey="common:performer.subscriptions.perks.more"
                  components={{
                    a1: (
                      // eslint-disable-next-line -- content is injected by i18n
                      <a
                        href="https://www.spotlight.com/the-small-print/what-we-offer-our-members/"
                        target="_blank"
                      />
                    ),
                  }}
                />
              )}
            </div>
          </div>
          {(!_isEmpty(annualSubscription) ||
            !_isEmpty(monthlySubscription)) && (
            <>
              <PlanSelector
                selectedPeriod={planPeriodData.selectedPeriod}
                setSelectedPeriod={(plan: PlanCodePeriod) => {
                  setIsCheckoutSpeedbumpOpen(true);
                  setPlanPeriodData({
                    selectedPeriod: plan,
                    selectedAddOns: [],
                  });
                }}
                setIsAddOnSelected={(addOn) => toggleAddOnSelected(addOn)}
                subscriptions={[annualSubscription, monthlySubscription]}
                currency={selectedPlan?.currency}
              />
              {isECPModalVariantFlagActive && (
                <AddOnPurchaseModal
                  preselectedAddOn={selectedPeriodAddOns?.[0]}
                  closePopUp={() => {
                    setIsCheckoutSpeedbumpOpen(false);
                    setPlanPeriodData({
                      selectedPeriod: undefined,
                      selectedAddOns: [],
                    });
                  }}
                  onSuccessCallback={() => {}}
                  texts={{
                    period: getPeriodText(planPeriodData.selectedPeriod!),
                    disclaimer: getModalTexts(true).disclaimer,
                    disclaimerLink: getModalTexts(true).disclaimerLink,
                  }}
                  onPrimaryCtaClick={() => {
                    if (selectedPeriodAddOns?.[0]) {
                      toggleAddOnSelected(selectedPeriodAddOns[0]);
                    }

                    if (handleClearSpeedbump) {
                      handleClearSpeedbump();
                    }
                  }}
                  onSecondaryCtaClick={handleClearSpeedbump}
                  isOpen={isCheckoutSpeedbumpOpen}
                />
              )}
            </>
          )}
        </div>
      </PageContent>
      {showStickyCtaBar && (
        <StickyCTABar
          prev={{
            text: t("common:button.label.back"),
            onClick: () => goBack?.(),
          }}
          next={{
            text: generateContinueButtonText(isYoungPerformer),
            onClick: () => goNext?.(),
            disabled: isContinueButtonDisabled,
          }}
          isSticky
        />
      )}
    </>
  );
};

export default SubscriptionPage;
