export const profile = {
  "MainPage.AvailabilityCalendarForm.AvailabilityCalendarForm.datePicker":
    "availability-calendar-datepicker",
  "MainPage.AvailabilityCalendarForm.AvailabilityCalendarForm.titleInput":
    "availability-calendar-title-input",
  "MainPage.RowGallery.RowGallery.editButton": "RowGallery_EditButton",
  "MainPage.RowGallery.RowGallery.photo": "RowGallery_Photo",
  "ProfileStatsPage.HelpTip.helpTipContent": "help-tip-content",
  "ProfileStatsPage.StatCard.totalViewsCount": "total-views-count",
  "LinkToProfilePage.CopyLinkButtonsSection.copyButton": "copy-button",
  "MultiSelectList.MultiSelectListItems.wrapper": "multi-select-list-items",
  "EditBasicInfo.BasicInfoForm.Nationality.nationalitiesField":
    "nationalities-field",
  "Accordion.AccordionItem.expandable-row": "expandable-row",
  "SkillsGrid.SkillsGrid-data-row": "skills-grid-row",
  "Skills.SkillsForm.skillContainer": "skill-container",
  "LinkToProfile.ProfileLink.profileLink": "profile-link",
  "LinkToProfile.CopyProfileLink.button": "profile-link-copy-button",
  "MainPage.TrainingSection": "profile-training-container",
  "MainPage.AgencyDetails.field": "profile-agency-details-heading",
  "ManageContactsPage.AgencyDetails.row": "agency-details",
  "AddAgency.AgencyFilter.dropdown": "add-agency",
  "SkillsGrid.SkillsGrid.CellValue": "skills-grid-cellValue",
  "BasicInfo.Edit.SaveButton": "basicInfo-save-button",
  "Credit.Edit.SaveButton": "credit-save-button",
  "Credit.Edit.AddButton": "credit-add-button",
  "Skill.Edit.SaveButton": "skill-save-button",
  "Media.TitaniumUploader.FileInput": "file-input",
  "Media.FilePreview.DeleteButton": "delete-button",
  "Media.VideoForm.TitleInput": "videoTitle-input",
  "Media.MediaForm.SaveButton": "-saveForm",
  "Media.Video.AddReelButton": "addReel-button",
  "Media.Video.UploadedVideo.List": "video-list",
  "Media.Video.UploadedVideo.Title": "video-title",
  "Media.MediaStorageContainer.CapacityBar": "mediaStorage-capacity",
  "Media.ThumbnailBar.ThumbnailBarItem": "thumbnailBar-element",
  "MainPage.MediaSection": "profile-media-container",
};
