import React, { FC, Fragment, useContext, useEffect } from "react";
import { Trans, useTranslation } from "i18n";
import cx from "classnames";
import { useDynamicWindowWidthFor, useScrollToElement } from "shared-hooks";
import SubscriptionCard from "../SubscriptionCard";
import {
  CurrencyType,
  IAddOns,
  ISubscription,
  PlanCodePeriod,
  planPeriodMap,
} from "../../../types";
import { SCREEN_WIDTH_ENUM } from "../../../../../../generics/dom-extensions";
import FeatureBox from "../FeatureBox";
import {
  BASE_ANNUAL_TEXTS,
  BASE_MONTHLY_TEXTS,
  getAddOnTexts,
  injectPlanButtonLabel,
  features,
} from "./PlanSelector.helpers";
import { BillingContext } from "../../../BillingContext";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";

interface IPlanSelectorProps {
  selectedPeriod: PlanCodePeriod | undefined;
  setSelectedPeriod: (plan: PlanCodePeriod) => void;
  setIsAddOnSelected: (addOn: IAddOns) => void;
  subscriptions: (ISubscription | undefined)[];
  currency?: CurrencyType;
}

const PlanSelector: FC<IPlanSelectorProps> = ({
  selectedPeriod,
  setSelectedPeriod,
  setIsAddOnSelected,
  currency = CurrencyType.GBP,
  subscriptions,
}) => {
  const { ref, scrollToElement } = useScrollToElement({
    block: "nearest",
    inline: "start",
  });
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { planPeriodData, enableAddOnsSelection } = useContext(BillingContext);
  const { t } = useTranslation();
  const isECPModalVariantFlagActive = useLaunchDarklyFlags(
    FEATURE_FLAGS.ECPModalVariant
  );

  const isAnnualSelected = selectedPeriod === PlanCodePeriod.Annual;

  const selectedPeriodAddOns = subscriptions.find(
    (sub) => selectedPeriod && sub?.code.includes(selectedPeriod)
  )?.addOns;

  const isAddOnSelected = (addOn: IAddOns) => {
    return planPeriodData.selectedAddOns.some((obj) => obj.code === addOn.code);
  };

  useEffect(() => {
    if (
      selectedPeriod &&
      !isECPModalVariantFlagActive &&
      enableAddOnsSelection
    ) {
      scrollToElement();
    }
  }, [selectedPeriod]);

  const annualTexts =
    selectedPeriod === PlanCodePeriod.Annual
      ? injectPlanButtonLabel(BASE_ANNUAL_TEXTS)
      : BASE_ANNUAL_TEXTS;
  const monthlyTexts =
    selectedPeriod === PlanCodePeriod.Monthly
      ? injectPlanButtonLabel(BASE_MONTHLY_TEXTS)
      : BASE_MONTHLY_TEXTS;

  const showPlanSelectorLabel =
    subscriptions.length > 1 && subscriptions.every(Boolean);
  const cleanedSubscriptions = subscriptions.filter(Boolean);

  const showAddOns =
    !!selectedPeriod &&
    selectedPeriodAddOns &&
    !isECPModalVariantFlagActive &&
    selectedPeriodAddOns?.length > 0 &&
    enableAddOnsSelection;

  return (
    <div className="flex flex-col justify-center w-full gap-4 my-0 lg:mx-auto">
      {showPlanSelectorLabel && (
        <p className="text-lg font-semibold">
          {t("common:billing.addOn.planSelector.label")}
        </p>
      )}
      <div
        className={cx("flex gap-2", {
          "flex-col items-stretch": isMobileView,
          "items-center justify-center": !isMobileView,
        })}
      >
        {cleanedSubscriptions.map((subscription, i) => {
          if (!subscription) return null;

          const isAnnualPlan = subscription.code.includes(
            PlanCodePeriod.Annual
          );
          const isLastItem = i === cleanedSubscriptions.length - 1;
          const planPeriod = planPeriodMap[subscription.code];
          const isSelected =
            !!selectedPeriod && !!planPeriod && selectedPeriod === planPeriod;

          return (
            <Fragment key={subscription.code}>
              <SubscriptionCard
                isSelected={isSelected}
                onSelect={() => setSelectedPeriod(planPeriod)}
                price={subscription.amountValue}
                pricePlusVat={subscription.totalValue}
                planPeriod={planPeriod}
                currency={subscription.currency}
                texts={isAnnualPlan ? annualTexts : monthlyTexts}
                showBadge={isAnnualPlan}
              />
              {!isMobileView && !isLastItem && (
                <p className="text-xl font-semibold uppercase">
                  {t("common:label.or")}
                </p>
              )}
            </Fragment>
          );
        })}
      </div>
      {showAddOns && (
        <div
          className="flex flex-col gap-2 px-2 pb-2 rounded-lg bg-gradient-to-br from-color-brand-two to-color-brand-one"
          ref={ref}
        >
          <p className="my-2 text-xl text-white">
            {t("common:label.additionalServices")}
          </p>
          {selectedPeriodAddOns?.map((addOn) => {
            const feature = features[addOn.code];

            return (
              <div key={addOn.code}>
                <FeatureBox
                  key={addOn.code}
                  texts={{
                    ...getAddOnTexts(addOn.code),
                    period: isAnnualSelected
                      ? "common:billing.planSelector.addOns.price.annual"
                      : "common:billing.planSelector.addOns.price.monthly",
                  }}
                  featurePrice={addOn.totalValue}
                  vatPrice={addOn.amount}
                  selectFeature={() => setIsAddOnSelected(addOn)}
                  isFeatureSelected={isAddOnSelected(addOn)}
                  features={feature.mainFeatures}
                  subFeatures={feature.subFeatures}
                  currency={currency}
                />
                {isECPModalVariantFlagActive && (
                  <p className="text-sm">
                    <Trans
                      i18nKey={getAddOnTexts(addOn.code).description}
                      components={{
                        link1: (
                          <a
                            href={getAddOnTexts(addOn.code).descriptionLink}
                            target="_blank"
                            rel="noreferrer"
                          />
                        ),
                      }}
                    />
                  </p>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PlanSelector;
