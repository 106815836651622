import { AxiosResponse } from "axios";
import { authAxios as axios } from "shared-auth";
import config from "config/global";
import { getDefaultEndpointsPreset } from "component-library/src/components/Organisms/Billing/services/billingEndpointsPresets";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";

const BILLING_API_URL = config.billingApiUrl || "";

export const MY_SUBSCRIPTION_QK = "my-subscriptions";

export const CANCEL_ADD_ON_MUTATION_KEY = "cancel-add-on";

export interface RecurlyError {
  RecurlyError: {
    params: { param: string }[];
  };
}

export interface IAddress {
  city: string;
  country: string;
  region: string;
  phone?: string;
  postal_code: string;
  street1: string;
  street2?: string;
}

export interface IAddOn {
  name: string;
  code: AddOnCodes;
  calculatedStatus: "inactive" | "active";
  description: string;
  url: string;
  availableToBuy: boolean;
  cancelled: boolean;
}

export interface IBillingApiResponse {
  subscription: {
    currentTermStartedAt: Date;
    currentTermEndsAt: Date;
    expiresAt: Date;
    state: "canceled" | "active";
    plan: string;
    planCode: string;
    calculatedStatus: string;
    id: string;
  };
  address: IAddress;
  account: {
    id: string;
    isUkPerformer: boolean;
    isEligibleForGradTransfer: boolean;
  };
  addOns: IAddOn[];
}

export interface IChangeSubscriptionPayload {
  planId: string;
}

export interface IRenewSubscriptionPayload extends IChangeSubscriptionPayload {
  couponCodes: string[];
}

export interface ITransferSubscriptionPayload
  extends IChangeSubscriptionPayload {
  planCurrency: string;
  addons: AddOnCodes[];
  couponCodes: string;
}

export interface ICancelAddOnPayload {
  addons: AddOnCodes[];
  subscriptionId: string;
}

interface IUpdateBillingAddress extends Exclude<IAddress, "region"> {
  stateProvince: string;
}

export const getRecurlyDetails = async (): Promise<
  undefined | IBillingApiResponse
> => {
  const { data } = await axios.get<IBillingApiResponse>(
    `${BILLING_API_URL}account/details`
  );

  return data;
};

export const updateAddress = async (
  params: IUpdateBillingAddress
): Promise<any> => {
  const { data } = await axios.put<Record<string, unknown>>(
    `${BILLING_API_URL}account/address`,
    params
  );

  return data;
};

export const cancelSubscription = async (): Promise<
  AxiosResponse | undefined
> => {
  const { data } = await axios.post<AxiosResponse>(
    `${BILLING_API_URL}subscription/cancel`
  );

  return data;
};

export const revertSubscription = async (): Promise<
  AxiosResponse | undefined
> => {
  const { data } = await axios.post<AxiosResponse>(
    `${BILLING_API_URL}subscription/reactivate`
  );

  return data;
};

export enum ChangeSubscriptionType {
  Upgrade = "upgrade",
  Downgrade = "downgrade",
}

export const changeSubscription = async (
  params: IChangeSubscriptionPayload
): Promise<AxiosResponse | undefined> => {
  const { data } = await axios.post<AxiosResponse>(
    `${BILLING_API_URL}subscription/change-plan`,
    params
  );

  return data;
};

export const renewSubscriptionWithExisting = async (
  params: IRenewSubscriptionPayload
): Promise<AxiosResponse | undefined> => {
  const { data } = await axios.post<AxiosResponse>(
    getDefaultEndpointsPreset(BILLING_API_URL).purchase,
    params
  );

  return data;
};

export const transferSubscriptionToGrad = async (
  params: ITransferSubscriptionPayload,
  overridePath: string
): Promise<AxiosResponse | undefined> => {
  const url = `${config.userAccountApiUrl}${overridePath}`;

  const { data } = await axios.post(url, params);

  return data;
};

export const applyFilmmakersTrialCoupon = async (): Promise<
  AxiosResponse | undefined
> => {
  const { data } = await axios.post<AxiosResponse>(
    `${BILLING_API_URL}coupons/apply/fm`
  );

  return data;
};

export const cancelAddOn = async (
  params: ICancelAddOnPayload
): Promise<AxiosResponse | undefined> => {
  return axios.post<AxiosResponse>(`${BILLING_API_URL}addons/cancel`, params);
};
