import React, { SyntheticEvent } from "react";
import { FormData, SetField } from "./hooks/useBillingFormValues";
import { AddOnCodes, PlanType } from "./constants";
import { SubscriptionCodes } from "./components/AddOnCheckout/SubscriptionPage/constants";

export interface IAddOns {
  amount: string;
  amountValue: number;
  total: string;
  totalValue: number;
  proRata: string;
  proRataValue: number;
  name: string;
  currency: CurrencyType;
  code: AddOnCodes;
}

export interface ISubscription {
  id: string;
  name: string;
  code: SubscriptionCodes;
  description: string;
  amount?: string;
  total?: string;
  totalValue: number;
  planType: PlanType;
  currency: CurrencyType;
  currencySymbol: string;
  amountValue: number;
  addOns: IAddOns[];
  autoRenewEnabled?: boolean;
}

export interface ISummary {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  discounts: string[];
}

export interface ITokenPayload {
  id: string;
  type: string;
}

export interface IPrice {
  total: string;
  discount: string;
  amount: string;
  tax: string;
  creditApplied?: string;
  nextBillingDate?: string;
  accountCredited?: string;
  addOns?: IAddOns[];
}

export interface ICouponMiscellaneous {
  isFreeTrial: boolean;
  freeTrialDescription: string;
}

export type ICouponObject = {
  couponCode: string;
  isFreeTrial: boolean;
  freeTrialDescription: string;
};

export interface IRecurlyError {
  data?: {
    RecurlyError: {
      message?: string;
      params: { message: string }[];
    };
  };
  RecurlyError?: {
    message?: string;
    params: { message: string }[];
  };
}

export type PreviewResponse = IPrice & IRecurlyError & ICouponMiscellaneous;

export interface IPurchaseResponse extends IRecurlyError {
  accountId: string;
  threeDSActionRequired: boolean;
  tokenId: string;
  actionTokenId?: string;
  billingInfoId?: string;
}

export type SelectPlan = (plan: ISubscription) => void;

export interface BillingAndPaymentFormProps {
  isFormOpen: boolean;
  formErrors: {
    [x: string]: string;
  };
  submitForm: (e: SyntheticEvent<any>) => void;
  billingFormRef: React.RefObject<HTMLFormElement>;
  sectionRef: React.RefObject<HTMLFormElement>;
  setFieldValue: SetField;
  isSubmitted: boolean;
  tootlipContainerConst: string;
  formValues: FormData;
  cardDetailError: Record<string, string>;
  removeInvalidCardError: (field: string) => void;
  planType?: PlanType;
  isMobileView?: boolean;
  isDirectDebit?: boolean;
  cardFormIsVisible?: boolean;
  planPeriod?: PlanCodePeriod;
  selectedMethod?: PlanCodeMethod;
  setPaymentMethod?: (paymentMethodType: PlanCodeMethod) => void;
  enableExistingPaymentMethod?: boolean;
}

export type IPlanPeriodData = {
  selectedPeriod: PlanCodePeriod | undefined;
  selectedAddOns: IAddOns[];
};

export enum CheckoutAccessTypes {
  GradsTransfer = "graduate-transfer",
  RenewalReactivation = "renewal-reactivation",
}

export enum CheckoutFlow {
  Default, // Default === Renewal/reactivation
  Joining,
  AccountSettings, // Account settings === Upgrade/downgrade premium (deprecated)
  GraduateTransfer, // GraduateTransfer === Convert from main perf to grad
  SubscriptionsModals, // SubscriptionsModals === AddOn purchase, reinstate subscription
  ChangePlan,
}

export const checkoutAccessTypeMap = {
  [CheckoutAccessTypes.GradsTransfer]: CheckoutFlow.GraduateTransfer,
  [CheckoutAccessTypes.RenewalReactivation]: CheckoutFlow.Default,
};

export enum PlanFilter {
  Classic = "classic",
  Premium = "premium",
  NoFilter = "",
  All = "all",
}

export enum PlanCodePeriod {
  Monthly = "monthly",
  Annual = "annual",
}

export enum PlanSelectorSearchParams {
  PreSelectedPlan = "preselectedPlan",
  PreSelectedAddOns = "preselectedAddOn",
}

export const planPeriodMap = {
  [SubscriptionCodes.Monthly_CC]: PlanCodePeriod.Monthly,
  [SubscriptionCodes.Monthly_DD]: PlanCodePeriod.Monthly,
  [SubscriptionCodes.Annual_CC]: PlanCodePeriod.Annual,
  [SubscriptionCodes.Annual_DD]: PlanCodePeriod.Annual,
  [SubscriptionCodes.YP_Monthly_CC]: PlanCodePeriod.Monthly,
  [SubscriptionCodes.YP_Monthly_DD]: PlanCodePeriod.Monthly,
  [SubscriptionCodes.YP_Annual_CC]: PlanCodePeriod.Annual,
  [SubscriptionCodes.YP_Annual_DD]: PlanCodePeriod.Annual,
  [SubscriptionCodes.Graduate_Annual_CC]: PlanCodePeriod.Annual,
  [SubscriptionCodes.Graduate_Annual_DD]: PlanCodePeriod.Annual,
  // [SubscriptionCodes.Graduate_Monthly_CC]: PlanCodePeriod.Monthly,
  [SubscriptionCodes.Graduate_Monthly_DD]: PlanCodePeriod.Monthly,
};

export enum PlanCodeMethod {
  DirectDebit = "dd",
  CreditCard = "cc",
}

export enum PaymentMethodType {
  CreditCard = "credit_card",
  Bacs = "bacs",
  Sepa = "sepa",
}

export enum CreditCardType {
  Visa = "Visa",
  Mastercard = "Mastercard",
  MasterCard = "MasterCard",
}

export enum CurrencyType {
  GBP = "GBP",
  EUR = "EUR",
}

export const currencyMap = {
  [CurrencyType.GBP]: "£",
  [CurrencyType.EUR]: "€",
};

export interface BankAccount {
  paymentMethodType: PaymentMethodType.Bacs | PaymentMethodType.Sepa;
  bankAccountNumber: string;
  address: string;
  accountHolderName: string;
}

export interface CreditCard {
  paymentMethodType: PaymentMethodType.CreditCard;
  cardNumber: string;
  address: string;
  accountHolderName: string;
  cardType: string;
}

export type ExistingPaymentMethod = BankAccount | CreditCard;
