import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTranslation } from "i18n";
import {
  Head,
  Spinner,
  generateBreadcrumbsData,
  AddOnPurchaseModal,
  NotificationToastTypes,
  NotificationToast,
} from "component-library";
import { AddOnEntitlements, MemberGroups } from "shared-types";
import { authorizationService } from "shared-auth";
import {
  cancelAddOn,
  getRecurlyDetails,
  IBillingApiResponse,
  MY_SUBSCRIPTION_QK,
  CANCEL_ADD_ON_MUTATION_KEY,
} from "services/api/BillingApi/BillingApi";
import { dataTestIds } from "data-testids";
import PageWrapperWithinLayout from "app-shell/PageWrapperWithinLayout/PageWrapperWithinLayout";
import applicationMap, { CheckoutType } from "services/applicationMap";
import { useNewAccSettingsPageAccessCheck } from "../AccountSettings/parts/hooks/useNewAccSettingsPageAccessCheck";
import CancellationModal from "components/Modals/CancellationModal";
import ReactivateModal from "components/Modals/ReactivateModal";
import CancelAddOnModal from "components/Modals/CancelAddOnModal";
import checkIsRenewalPeriod from "helpers/checkIsRenewalPeriod";
import { PlanCodePeriod } from "component-library/src/components/Organisms/Billing/types";
import { useUpdateDetailsPopUp } from "app/hooks/useUpdateDetailsPopUp";
import { useReturnUrl } from "shared-hooks";
import checkIsValidAddress from "helpers/checkIsValidAddress";
import {
  AddOnCalculatedStatus,
  BreadcrumbSection,
  SubscriptionCalculatedStatus,
  breadcrumbs,
} from "app/constants";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { AccountTypeBillingTypeMap } from "config/common";
import SettingSection from "components/SettingSection/SettingSection";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import TransferToGrads from "./TransferToGrads";
import SubscriptionData from "./SubscriptionData";
import { getCheckoutLink } from "helpers/getCheckoutLink";
import ReinstateModal from "components/Modals/ReinstateModal";
import { SubscriptionCodes } from "component-library/src/components/Organisms/Billing/components/AddOnCheckout/SubscriptionPage/constants";
import getLongDate from "helpers/getLongDate";
import { REINSTATE_SEARCH_PARAM } from "pages/IndexPage/constants/constants";

const FM_ADD_ONS_PREFIX = "fm";

const MySubscriptions = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const history = useHistory();
  const reinstate = new URLSearchParams(history.location.search).get(
    REINSTATE_SEARCH_PARAM
  );
  const [selectedAddon, setSelectedAddon] = useState<AddOnCodes>();
  useNewAccSettingsPageAccessCheck();
  const [isReinstatePlanModalOpen, setIsReinstatePlanModalOpen] = useState(
    reinstate === "true"
  );
  const [isCancelPlanModalOpen, setIsCancelPlanModalOpen] = useState(false);
  const [revertModalIsOpen, setRevertModalIsOpen] = useState(false);
  const [isCancelAddOnModalOpen, setIsCancelAddOnModalOpen] = useState(false);
  const [toastState, setToastState] = useState({
    isOpen: false,
    message: "",
    type: NotificationToastTypes.Success,
  });
  const { redirectWithReturnUrl } = useReturnUrl();

  const { [FEATURE_FLAGS.ConvertToGrad]: isConvertToGradFlagEnabled } =
    useLaunchDarklyFlags([FEATURE_FLAGS.ConvertToGrad]);

  // errors caught by boundary
  const {
    data: mySubscriptionData,
    isLoading,
    refetch,
  } = useQuery<IBillingApiResponse | undefined>({
    queryKey: MY_SUBSCRIPTION_QK,
    queryFn: async () => await getRecurlyDetails(),
    cacheTime: 0,
  });
  const { mutate, isLoading: isCancelAddOnsLoading } = useMutation({
    mutationKey: CANCEL_ADD_ON_MUTATION_KEY,
    mutationFn: cancelAddOn,
    onSuccess: async () => {
      setIsCancelAddOnModalOpen(false);
      await queryClient.invalidateQueries(MY_SUBSCRIPTION_QK);
    },
  });

  const { subscription, address, addOns, account } = mySubscriptionData || {};

  const isExpired =
    subscription?.calculatedStatus === SubscriptionCalculatedStatus.Expired;
  const isValidAddress = checkIsValidAddress(address);
  const renewalLink = getCheckoutLink({
    planCode: subscription?.planCode,
  });
  const reactivationLink = getCheckoutLink({
    planCode: subscription?.planCode,
    checkoutType: CheckoutType.Reactivation,
  });
  const targetLink = isExpired ? reactivationLink : renewalLink;
  // TODO: In SL-7453, we may be setting up a dedicated change page (distinct from renewal or reactivation). We may want to reidrect there instead of /renewal
  const changePlanLink = getCheckoutLink({
    planCode: subscription?.planCode,
    checkoutType: CheckoutType.Change,
    addOns: addOns
      ?.filter(
        (addOn) => addOn.calculatedStatus === AddOnCalculatedStatus.Active
      )
      .map((addOn) => addOn.code),
  });
  const { showUpdateDetailsPopUp } = useUpdateDetailsPopUp(() =>
    redirectWithReturnUrl(applicationMap.routes.settingsMyDetails(), targetLink)
  );

  // ADD ON SECTION CONDITIONS
  const isMainPerformer =
    authorizationService.getUserGroup() === MemberGroups.performer;

  const goToMyAccount = () =>
    history.replace(applicationMap.routes.accountSettings());

  const addOnSuccessCallback = (trackingData: any = {}) => {
    const userGroup = authorizationService.getUserGroup();

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.AddOnPurchase,
      memberType:
        AccountTypeBillingTypeMap[
          userGroup as keyof typeof AccountTypeBillingTypeMap
        ],
      ...trackingData,
    });
    refetch();
    setSelectedAddon(undefined);
  };

  if (isLoading) return <Spinner />;

  const hasAddOnBundle = authorizationService.doesUserHaveEntitlements(
    AddOnEntitlements.AddOnBundle
  );
  const isRenewalPeriod = checkIsRenewalPeriod({
    planCode: subscription?.planCode,
    calculatedStatus: subscription?.calculatedStatus,
  });

  const isMonthlyPayingPremiumUser =
    hasAddOnBundle && subscription?.planCode.includes(PlanCodePeriod.Monthly);
  const isAnnuallyPayingPremiumRenewalPeriod =
    hasAddOnBundle &&
    isRenewalPeriod &&
    subscription?.planCode.includes(PlanCodePeriod.Annual);
  const isPrimaryButtonEnabled =
    !hasAddOnBundle ||
    isMonthlyPayingPremiumUser ||
    isAnnuallyPayingPremiumRenewalPeriod;

  const shouldShowTransferToGrads =
    isMainPerformer && isConvertToGradFlagEnabled;

  const generatePrimaryButtonLabel = () => {
    if (hasAddOnBundle) {
      return t("common:button.label.changePlan");
    }

    if (isExpired) {
      return t("common:button.label.reactivateSubscription");
    }

    if (isRenewalPeriod) {
      return t("common:button.label.renewSubscription");
    }

    return "";
  };

  const handleAddressInvalidRedirect = () => {
    if (!isValidAddress) {
      showUpdateDetailsPopUp();

      return false;
    }

    return true;
  };

  const primaryButtonProps = {
    label: generatePrimaryButtonLabel(),
    isDisabled: !isPrimaryButtonEnabled,
    onClick: () => {
      if (handleAddressInvalidRedirect()) history.push(targetLink);
    },
    isHidden: !isExpired && !isRenewalPeriod,
  };

  const handleChangePlan = () => {
    if (handleAddressInvalidRedirect()) history.push(changePlanLink);
  };

  const addOnsToCancel =
    mySubscriptionData?.addOns
      ?.filter((addOn) => addOn.code.includes(FM_ADD_ONS_PREFIX))
      .map((filteredAddOn) => filteredAddOn.code) || [];

  const activeAddOns =
    mySubscriptionData?.addOns
      ?.filter(
        (addOn) => addOn.calculatedStatus === AddOnCalculatedStatus.Active
      )
      .map((filteredAddOn) => filteredAddOn.code) || [];

  const formattedDate = subscription?.currentTermEndsAt
    ? getLongDate(subscription?.currentTermEndsAt)
    : "-";

  const handleSubscriptionCancelled = () => {
    setIsCancelPlanModalOpen(false);
    setToastState({
      ...toastState,
      isOpen: true,
      message: t("performer:accountSettings.mySubscriptionPage.canceled.toast"),
    });
  };

  const handleSubscriptionReinstated = () => {
    setIsReinstatePlanModalOpen(false);
    setToastState({
      ...toastState,
      isOpen: true,
      message: t(
        "performer:accountSettings.mySubscriptionPage.reinstated.toast"
      ),
    });
  };

  return (
    <>
      <Head>
        <title data-testid={dataTestIds.userAccountApp}>
          {t("performer:accountSettings.mySubscription.header")}
        </title>
      </Head>
      <PageWrapperWithinLayout
        pageHeader={t("performer:accountSettings.mySubscription.header")}
        subFooter={{
          text: t("common:button.label.close"),
          onClick: goToMyAccount,
        }}
        breadcrumbs={generateBreadcrumbsData(breadcrumbs, [
          BreadcrumbSection.MyAccount,
          BreadcrumbSection.AccountSettings,
        ])}
      >
        <SettingSection
          sectionDescription={t(
            "performer:accountSettings.mySubscriptionPage.description"
          )}
          subSectionHeader={t(
            "common:accountSettings.mySubscription.subSection.title"
          )}
          actionButtonLabel={primaryButtonProps.label}
          actionButtonOnClick={primaryButtonProps.onClick}
          actionButtonDisabled={primaryButtonProps.isDisabled}
          actionButtonHidden={primaryButtonProps.isHidden}
        >
          <SubscriptionData
            handleCancelPlan={() => setIsCancelPlanModalOpen(true)}
            handleChangePlan={handleChangePlan}
            handleReinstatePlan={() => setIsReinstatePlanModalOpen(true)}
            subscription={subscription}
            addOns={addOns}
            onBuyAddOnClick={(code) => setSelectedAddon(code)}
            onCancelAddOnClick={() => setIsCancelAddOnModalOpen(true)}
            formattedDate={formattedDate}
          />
          {shouldShowTransferToGrads ? (
            <>
              <hr className="mt-2.5 mb-5" />
              <TransferToGrads enabled={!!account?.isEligibleForGradTransfer} />
            </>
          ) : undefined}
        </SettingSection>
        <CancellationModal
          isOpen={isCancelPlanModalOpen}
          setSubscriptionSuccessCancelled={handleSubscriptionCancelled}
          handleChangePlan={handleChangePlan}
          closePopUp={() => setIsCancelPlanModalOpen(false)}
          formattedDate={formattedDate}
        />
        <ReinstateModal
          isOpen={isReinstatePlanModalOpen}
          closePopUp={() => setIsReinstatePlanModalOpen(false)}
          onSuccessCallback={handleSubscriptionReinstated}
          planCode={subscription?.planCode as SubscriptionCodes}
          formattedDate={formattedDate}
          activeAddOns={activeAddOns}
        />
        <ReactivateModal
          isOpen={revertModalIsOpen}
          closePopUp={() => setRevertModalIsOpen(false)}
          onSuccessCallback={refetch}
        />
        <CancelAddOnModal
          isOpen={isCancelAddOnModalOpen}
          closePopUp={() => setIsCancelAddOnModalOpen(false)}
          isCancelAddOnLoading={isCancelAddOnsLoading}
          onSubmitCancelAddOn={() =>
            mutate({
              subscriptionId: mySubscriptionData?.subscription.id || "",
              addons: addOnsToCancel,
            })
          }
        />
        <AddOnPurchaseModal
          selectedAddOnCode={selectedAddon}
          closePopUp={() => setSelectedAddon(undefined)}
          onSuccessCallback={addOnSuccessCallback}
        />
        <NotificationToast
          className="absolute bottom-0 min-w-[300px] w-content m-auto left-0 right-0"
          show={toastState.isOpen}
          text={toastState.message}
          type={toastState.type}
          handleHide={() => setToastState({ ...toastState, isOpen: false })}
        />
      </PageWrapperWithinLayout>
    </>
  );
};

export default MySubscriptions;
