import _isEmpty from "lodash/isEmpty";
import { IUserInformation, MembershipStatuses } from "models/page";
import {
  IMyAccountPageTranslation,
  MediaPickerDataTypes,
} from "models/translation";
import { AddOnEntitlements, PremiumEntitlements } from "shared-types";
import { authorizationService, checkIsFilmmakersProfile } from "shared-auth";
import config from "config/global";
import { getCheckoutLink } from "helpers/getCheckoutLink";
import { IBillingApiResponse } from "services/api/BillingApi/BillingApi";
import { agentsQuickLinks } from "pages/IndexPage/constants/agentsQuickLinks";
import {
  castingDirectorDefaultQuickLinks,
  castingDirectorInactiveQuickLinks,
  castingDirectorSearchOnlyQuickLinks,
} from "pages/IndexPage/constants/castingQuickLinks";
import {
  filmmakersQuickLinks,
  performersAddOnBundleQuickLinks,
  performersDefaultQuickLinks,
  performersGraduatesQuickLinks,
  performersInactiveQuickLinks,
  performersYPQuickLinks,
} from "pages/IndexPage/constants/performersQuickLinks";
import { CheckoutType } from "services/applicationMap";

const CONTACT_US_BY_EMAIL_ABOUT_NAMES_URL = "mailto:names@spotlight.com";

function getRenewUrl(
  performerSubscriptionData: IBillingApiResponse | undefined
) {
  if (!_isEmpty(performerSubscriptionData)) {
    return getCheckoutLink({
      planCode: performerSubscriptionData.subscription.planCode,
    });
  } else return "";
}

function getReactivateUrl() {
  return getCheckoutLink({ checkoutType: CheckoutType.Reactivation });
}

function getSignOutUrl() {
  return (config.siteRootUrl || "").replace(/\/$/, "") + "/logout.aspx";
}

export function isAccountStatusActive(userAccountStatus: MembershipStatuses) {
  return (
    userAccountStatus === "current" ||
    userAccountStatus == "renewing" ||
    userAccountStatus == "coolingoff"
  );
}

function formatString(text: string | undefined, values: Record<string, any>) {
  text = text || "";
  for (var key in values) {
    text = text.replace(
      new RegExp("\\{" + key + "\\}", "gi"),
      values[key] == null ? "" : values[key]
    );
  }

  return text;
}

export interface IQuickLink {
  text: string;
  href: string;
}

export function getQuickLinksForUser(
  userInfo: IUserInformation
): undefined | IQuickLink[] {
  const accountActive = isAccountStatusActive(userInfo.membershipStatus);
  const isPremierLEGACY = authorizationService.doesUserHaveEntitlements(
    PremiumEntitlements.Premium_Site_Access
  );
  const hasAddOnBundle = authorizationService.doesUserHaveEntitlements(
    AddOnEntitlements.AddOnBundle
  );
  const isFilmmakersProfile = checkIsFilmmakersProfile();

  const isEntitled = isPremierLEGACY || hasAddOnBundle;

  switch (userInfo.memberGroup) {
    case "castingDirector": {
      if (!accountActive) return castingDirectorInactiveQuickLinks;

      if (userInfo.memberSubType === "searchOnlyCastingDirector")
        return castingDirectorSearchOnlyQuickLinks;

      return castingDirectorDefaultQuickLinks;
    }
    case "performer": {
      if (!accountActive) return performersInactiveQuickLinks;

      if (isFilmmakersProfile) return filmmakersQuickLinks;

      if (userInfo.memberSubType == "youngPerformer")
        return performersYPQuickLinks;

      if (userInfo.memberSubType === "graduate")
        return performersGraduatesQuickLinks;

      if (isEntitled) return performersAddOnBundleQuickLinks;

      return performersDefaultQuickLinks;
    }
    case "agent":
      return agentsQuickLinks;
  }

  return undefined;
}

function contactUsByEmailAboutNamesButton(
  translations: IMyAccountPageTranslation
) {
  return {
    text:
      translations["account Status Banner"]
        ?.accountStatusBannerContactUsButtonText || "",
    title:
      translations["account Status Banner"]
        ?.accountStatusBannerContactUsButtonText || "",
    href: CONTACT_US_BY_EMAIL_ABOUT_NAMES_URL,
  };
}

function renewButton(
  translations: IMyAccountPageTranslation,
  performerSubscriptionData: IBillingApiResponse | undefined
) {
  return {
    text:
      translations["account Status Banner"]
        ?.accountStatusBannerRenewButtonText || "",
    title:
      translations["account Status Banner"]
        ?.accountStatusBannerRenewButtonText || "",
    href: getRenewUrl(performerSubscriptionData),
  };
}

function reactivateButton(translations: IMyAccountPageTranslation) {
  return {
    text:
      translations["account Status Banner"]
        ?.accountStatusBannerReactivateNowButtonText || "",
    title:
      translations["account Status Banner"]
        ?.accountStatusBannerReactivateNowButtonText || "",
    href: getReactivateUrl(),
  };
}

function signOutButton(translations: IMyAccountPageTranslation) {
  return {
    text:
      translations["account Status Banner"]
        ?.accountStatusBannerSignOutButtonText || "",
    title:
      translations["account Status Banner"]
        ?.accountStatusBannerSignOutButtonText || "",
    href: getSignOutUrl(),
    type: "secondary",
  };
}

const noNotificationBanner = {
  accountStatusNotificationMessage: undefined,
  accountStatusNotificationButtons: [],
} as const;

export type HtmlAnchorButton = {
  text: string;
  title: string;
  href: string;
  type?: "secondary" | "primary";
  target?: string;
  onClick?: () => void;
  className?: string;
};

export function getAccountStatusNotificationForUser(
  userInfo: IUserInformation,
  hasPaidRecently: boolean,
  translations: IMyAccountPageTranslation,
  performerSubscriptionData: IBillingApiResponse | undefined
): {
  accountStatusNotificationMessage: string | undefined;
  accountStatusNotificationButtons: ReadonlyArray<HtmlAnchorButton>;
} {
  switch (userInfo.memberGroup) {
    case "agent":
      return noNotificationBanner;
    case "performer":
      switch (userInfo.membershipStatus) {
        case "current":
        case "coolingoff":
          return noNotificationBanner;
        case "suspended":
          return {
            accountStatusNotificationMessage:
              translations["account Status Banner"]
                ?.accountStatusBannerPerformerSuspended,
            accountStatusNotificationButtons: [],
          };
        case "renewing":
          if (userInfo.disableOnlineReactivate) {
            return {
              accountStatusNotificationMessage:
                translations["account Status Banner"]
                  ?.accountStatusBannerDisableOnlineReactivate,
              accountStatusNotificationButtons: [],
            };
          }

          if (hasPaidRecently) {
            return noNotificationBanner;
          }

          if (userInfo.memberSubType == "graduate") {
            return {
              accountStatusNotificationMessage: formatString(
                translations["account Status Banner"]
                  ?.accountStatusBannerGraduatePerformerNeedsRenewing,
                { daysRemaining: userInfo.daysRemaining }
              ),
              accountStatusNotificationButtons: [
                renewButton(translations, performerSubscriptionData),
              ],
            };
          } else {
            return {
              accountStatusNotificationMessage: formatString(
                translations["account Status Banner"]
                  ?.accountStatusBannerPerformerNeedsRenewing,
                { daysRemaining: userInfo.daysRemaining }
              ),
              accountStatusNotificationButtons: [
                renewButton(translations, performerSubscriptionData),
              ],
            };
          }
        case "cancelled":
        case "expired":
        default:
          if (userInfo.disableOnlineReactivate) {
            return {
              accountStatusNotificationMessage:
                translations["account Status Banner"]
                  ?.accountStatusBannerDisableOnlineReactivate,
              accountStatusNotificationButtons: [],
            };
          }

          if (userInfo.memberSubType == "graduate") {
            if (hasPaidRecently) {
              return noNotificationBanner;
            }

            return {
              accountStatusNotificationMessage:
                translations["account Status Banner"]
                  ?.accountStatusBannerGraduatePerformerExpired,
              accountStatusNotificationButtons: [
                signOutButton(translations),
                reactivateButton(translations),
              ],
            };
          } else {
            if (userInfo.performerNameTaken) {
              return {
                accountStatusNotificationMessage:
                  translations["account Status Banner"]
                    ?.accountStatusBannerPerformerExpiredAndNameTaken,
                accountStatusNotificationButtons: [
                  contactUsByEmailAboutNamesButton(translations),
                ],
              };
            }

            if (hasPaidRecently) {
              return noNotificationBanner;
            }

            return {
              accountStatusNotificationMessage:
                translations["account Status Banner"]
                  ?.accountStatusBannerPerformerExpired,
              accountStatusNotificationButtons: [
                signOutButton(translations),
                reactivateButton(translations),
              ],
            };
          }
      }
    case "castingDirector":
      switch (userInfo.membershipStatus) {
        case "current":
        case "coolingoff":
          return noNotificationBanner;
        case "renewing":
          if (userInfo.disableOnlineReactivate) {
            return {
              accountStatusNotificationMessage:
                translations["account Status Banner"]
                  ?.accountStatusBannerDisableOnlineReactivate,
              accountStatusNotificationButtons: [],
            };
          }

          if (hasPaidRecently) {
            return noNotificationBanner;
          }

          return {
            accountStatusNotificationMessage: formatString(
              translations["account Status Banner"]
                ?.accountStatusBannerCastingDirectorNeedsRenewing,
              { daysRemaining: userInfo.daysRemaining }
            ),
            accountStatusNotificationButtons: [
              {
                text:
                  translations["account Status Banner"]
                    ?.accountStatusBannerRenewButtonText || "",
                title:
                  translations["account Status Banner"]
                    ?.accountStatusBannerRenewButtonText || "",
                href: getCheckoutLink(),
              },
            ],
          };
        case "cancelled":
        case "expired":
        default:
          if (userInfo.disableOnlineReactivate) {
            return {
              accountStatusNotificationMessage:
                translations["account Status Banner"]
                  ?.accountStatusBannerDisableOnlineReactivate,
              accountStatusNotificationButtons: [],
            };
          }

          if (hasPaidRecently) {
            return noNotificationBanner;
          }

          return {
            accountStatusNotificationMessage:
              translations["account Status Banner"]
                ?.accountStatusBannerCastingDirectorExpired,
            accountStatusNotificationButtons: [
              signOutButton(translations),
              reactivateButton(translations),
            ],
          };
      }
  }
}

export function getMarketingMessageForUser(
  userInfo: IUserInformation,
  translations: IMyAccountPageTranslation
): {
  marketingBannerHeading: string;
  marketingBannerBody: string;
  marketingBannerCallToActionUrl: string;
  marketingBannerBackgroundImage: MediaPickerDataTypes | undefined | null;
  marketingBannerCallToActionText: string;
} {
  const isPremierLEGACY = authorizationService.doesUserHaveEntitlements(
    PremiumEntitlements.Premium_Site_Access
  );
  const hasAddOnBundle = authorizationService.doesUserHaveEntitlements(
    AddOnEntitlements.AddOnBundle
  );
  const isEntitled = isPremierLEGACY || hasAddOnBundle;

  switch (userInfo.memberGroup) {
    case "agent":
      return {
        marketingBannerHeading:
          translations["marketing Banner - Agent"]
            ?.marketingBannerAgentHeading || "",
        marketingBannerBody:
          translations["marketing Banner - Agent"]?.marketingBannerAgentBody ||
          "",
        marketingBannerCallToActionUrl:
          translations["marketing Banner - Agent"]
            ?.marketingBannerAgentCallToActionUrl || "#",
        marketingBannerBackgroundImage:
          translations["marketing Banner - Agent"]
            ?.marketingBannerAgentBackgroundImage,
        marketingBannerCallToActionText:
          translations["marketing Banner - Agent"]
            ?.marketingBannerAgentCallToActionText || "",
      };
    case "castingDirector":
      return {
        marketingBannerHeading:
          translations["marketing Banner - Casting Director"]
            ?.marketingBannerCastingDirectorHeading || "",
        marketingBannerBody:
          translations["marketing Banner - Casting Director"]
            ?.marketingBannerCastingDirectorBody || "",
        marketingBannerCallToActionUrl:
          translations["marketing Banner - Casting Director"]
            ?.marketingBannerCastingDirectorCallToActionUrl || "#",
        marketingBannerBackgroundImage:
          translations["marketing Banner - Casting Director"]
            ?.marketingBannerCastingDirectorBackgroundImage,
        marketingBannerCallToActionText:
          translations["marketing Banner - Casting Director"]
            ?.marketingBannerCastingDirectorCallToActionText || "",
      };
    case "performer":
      if (userInfo.memberSubType === "youngPerformer")
        return {
          marketingBannerHeading:
            translations["marketing Banner - Young Performer"]
              ?.marketingBannerGraduateHeading || "",
          marketingBannerBody:
            translations["marketing Banner - Young Performer"]
              ?.marketingBannerGraduateBody || "",
          marketingBannerCallToActionUrl:
            translations["marketing Banner - Young Performer"]
              ?.marketingBannerGraduateCallToActionUrl || "#",
          marketingBannerBackgroundImage:
            translations["marketing Banner - Young Performer"]
              ?.marketingBannerGraduateBackgroundImage,
          marketingBannerCallToActionText:
            translations["marketing Banner - Young Performer"]
              ?.marketingBannerGraduateCallToActionText || "",
        };

      if (userInfo.memberSubType === "graduate") {
        return {
          marketingBannerHeading:
            translations["marketing Banner - Graduate"]
              ?.marketingBannerGraduateHeading || "",
          marketingBannerBody:
            translations["marketing Banner - Graduate"]
              ?.marketingBannerGraduateBody || "",
          marketingBannerCallToActionUrl:
            translations["marketing Banner - Graduate"]
              ?.marketingBannerGraduateCallToActionUrl || "#",
          marketingBannerBackgroundImage:
            translations["marketing Banner - Graduate"]
              ?.marketingBannerGraduateBackgroundImage,
          marketingBannerCallToActionText:
            translations["marketing Banner - Graduate"]
              ?.marketingBannerGraduateCallToActionText || "",
        };
      }

      if (isEntitled) {
        return {
          marketingBannerHeading:
            translations["marketing Banner - Performer (Add On - Bundle)"]
              ?.marketingBannerPerformerAddOnBundleHeading || "",
          marketingBannerBody:
            translations["marketing Banner - Performer (Add On - Bundle)"]
              ?.marketingBannerPerformerAddOnBundleBody || "",
          marketingBannerCallToActionUrl:
            translations["marketing Banner - Performer (Add On - Bundle)"]
              ?.marketingBannerPerformerAddOnBundleCallToActionUrl || "#",
          marketingBannerBackgroundImage:
            translations["marketing Banner - Performer (Add On - Bundle)"]
              ?.marketingBannerPerformerAddOnBundleBackgroundImage,
          marketingBannerCallToActionText:
            translations["marketing Banner - Performer (Add On - Bundle)"]
              ?.marketingBannerPerformerAddOnBundleCallToActionText || "",
        };
      }

    // eslint-disable-next-line no-fallthrough
    default:
      return {
        marketingBannerHeading:
          translations["marketing Banner - Performer"]
            ?.marketingBannerPerformerHeading || "",
        marketingBannerBody:
          translations["marketing Banner - Performer"]
            ?.marketingBannerPerformerBody || "",
        marketingBannerCallToActionUrl:
          translations["marketing Banner - Performer"]
            ?.marketingBannerPerformerCallToActionUrl || "#",
        marketingBannerBackgroundImage:
          translations["marketing Banner - Performer"]
            ?.marketingBannerPerformerBackgroundImage,
        marketingBannerCallToActionText:
          translations["marketing Banner - Performer"]
            ?.marketingBannerPerformerCallToActionText || "",
      };
  }
}

export function getServiceBannerForUser(
  userInfo: IUserInformation,
  translations: IMyAccountPageTranslation
): {
  serviceBannerHeading: string | undefined;
  serviceBannerMessage: string | undefined;
  serviceBannerMedia: MediaPickerDataTypes | undefined | null;
} {
  const isPremierLEGACY = authorizationService.doesUserHaveEntitlements(
    PremiumEntitlements.Premium_Site_Access
  );
  const hasAddOnBundle = authorizationService.doesUserHaveEntitlements(
    AddOnEntitlements.AddOnBundle
  );
  const isEntitled = isPremierLEGACY || hasAddOnBundle;
  const accountActive = isAccountStatusActive(userInfo.membershipStatus);
  switch (userInfo.memberGroup) {
    case "castingDirector":
      if (accountActive) {
        if (userInfo.memberSubType === "searchOnlyCastingDirector") {
          return {
            serviceBannerHeading:
              translations["service Banner - Casting Director (search only)"]
                ?.serviceBannerCastingDirectorSearchOnlyHeading,
            serviceBannerMessage:
              translations["service Banner - Casting Director (search only)"]
                ?.serviceBannerCastingDirectorSearchOnlyBody,
            serviceBannerMedia:
              translations["service Banner - Casting Director (search only)"]
                ?.serviceBannerCastingDirectorSearchOnlyMedia,
          };
        } else {
          return {
            serviceBannerHeading:
              translations["service Banner - Casting Director (default)"]
                ?.serviceBannerCastingDirectorDefaultHeading,
            serviceBannerMessage:
              translations["service Banner - Casting Director (default)"]
                ?.serviceBannerCastingDirectorDefaultBody,
            serviceBannerMedia:
              translations["service Banner - Casting Director (default)"]
                ?.serviceBannerCastingDirectorDefaultMedia,
          };
        }
      } else {
        return {
          serviceBannerHeading:
            translations["service Banner - Casting Director (inactive)"]
              ?.serviceBannerCastingDirectorInactiveHeading,
          serviceBannerMessage:
            translations["service Banner - Casting Director (inactive)"]
              ?.serviceBannerCastingDirectorInactiveBody,
          serviceBannerMedia:
            translations["service Banner - Casting Director (inactive)"]
              ?.serviceBannerCastingDirectorInactiveMedia,
        };
      }
    case "performer":
      if (accountActive) {
        if (userInfo.memberSubType === "graduate") {
          return {
            serviceBannerHeading:
              translations["service Banner - Graduate"]
                ?.serviceBannerGraduateHeading,
            serviceBannerMessage:
              translations["service Banner - Graduate"]
                ?.serviceBannerGraduateBody,
            serviceBannerMedia:
              translations["service Banner - Graduate"]
                ?.serviceBannerGraduateMedia,
          };
        }

        if (userInfo.memberSubType === "youngPerformer") {
          return {
            serviceBannerHeading:
              translations["service Banner - Young Performer"]
                ?.serviceBannerPerformerYoungHeading,
            serviceBannerMessage:
              translations["service Banner - Young Performer"]
                ?.serviceBannerPerformerYoungBody,
            serviceBannerMedia:
              translations["service Banner - Young Performer"]
                ?.serviceBannerPerformerYoungMedia,
          };
        }

        if (isEntitled) {
          return {
            serviceBannerHeading:
              translations["service Banner - Performer (Add On - Bundle)"]
                ?.serviceBannerPerformerAddOnBundleHeading,
            serviceBannerMessage:
              translations["service Banner - Performer (Add On - Bundle)"]
                ?.serviceBannerPerformerAddOnBundleBody,
            serviceBannerMedia:
              translations["service Banner - Performer (Add On - Bundle)"]
                ?.serviceBannerPerformerAddOnBundleMedia,
          };
        }

        return {
          serviceBannerHeading:
            translations["service Banner - Performer (default)"]
              ?.serviceBannerPerformerDefaultHeading,
          serviceBannerMessage:
            translations["service Banner - Performer (default)"]
              ?.serviceBannerPerformerDefaultBody,
          serviceBannerMedia:
            translations["service Banner - Performer (default)"]
              ?.serviceBannerPerformerDefaultMedia,
        };
      } else {
        return {
          serviceBannerHeading:
            translations["service Banner - Performer (inactive)"]
              ?.serviceBannerPerformerInactiveHeading,
          serviceBannerMessage:
            translations["service Banner - Performer (inactive)"]
              ?.serviceBannerPerformerInactiveBody,
          serviceBannerMedia:
            translations["service Banner - Performer (inactive)"]
              ?.serviceBannerPerformerInactiveMedia,
        };
      }
    case "agent":
      return {
        serviceBannerHeading:
          translations["service Banner - Agent"]?.serviceBannerAgentHeading,
        serviceBannerMessage:
          translations["service Banner - Agent"]?.serviceBannerAgentBody,
        serviceBannerMedia:
          translations["service Banner - Agent"]?.serviceBannerAgentMedia,
      };
  }

  return {
    serviceBannerHeading: undefined,
    serviceBannerMessage: undefined,
    serviceBannerMedia: undefined,
  };
}
