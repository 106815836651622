import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BillingUser,
  BillingUserWithoutCommon,
} from "translations/src/models/billing";
import { billingService } from "./services";
import {
  CheckoutFlow,
  IAddOns,
  IPlanPeriodData,
  PlanCodePeriod,
  PlanFilter,
} from "./types";
import { AddOnCodes, customDefaultText } from "./constants";

interface IBillingContextProps {
  billingUserType: BillingUserWithoutCommon;
  goBack?: () => void;
  goNext?: () => void;
  goBackText?: string;
  checkoutText?: string;
  checkoutFlow: CheckoutFlow;
  queryAuthParams?: Record<string, any> | undefined;
  postCheckoutCallback?: (
    data: Record<string, any>,
    trackingData: Record<string, any>,
    isUsingExistingPaymentMethod?: boolean
  ) => Promise<any> | void;
  successCallback?: () => void;
  navigateToCompletePage?: (isDirectDebit?: boolean) => void;
  onPaymentSuccessGoBackAction?: () => void;
  disableDiscountCodeField?: boolean;
  planTypeFilterOverride?: PlanFilter;
  isCommonHeader?: boolean;
  customTexts?: Record<string, any>;
  isHiddenPeriodToggle?: boolean;
  preselectedPlanOverride?: PlanFilter | PlanCodePeriod;
  preselectedAddOnsOverride?: AddOnCodes[];
  planPeriodOverride?: PlanCodePeriod;
  presetExistingPaymentMethod?: boolean;
  enableAddOnsSelection?: boolean;
}

interface IBillingContext extends IBillingContextProps {
  planCodeFilter?: string;
  planTypeFilter?: string;
  planPeriodFilter?: string;
  setPlanTypeFilter: (planFilter: PlanFilter) => void;
  useExistingPaymentMethod: boolean;
  setUseExistingPaymentMethod: Dispatch<SetStateAction<boolean>>;
  planPeriodData: IPlanPeriodData;
  setPlanPeriodData: Dispatch<SetStateAction<IPlanPeriodData>>;
  toggleAddOnSelected: (addOn: IAddOns) => void;
}

export const BillingContext = createContext<IBillingContext>({
  billingUserType: BillingUser.Casting,
  useExistingPaymentMethod: false,
  setUseExistingPaymentMethod: () => {},
  setPlanTypeFilter: () => {},
  checkoutFlow: CheckoutFlow.Default,
  planPeriodData: {
    selectedPeriod: undefined,
    selectedAddOns: [],
  },
  setPlanPeriodData: () => {},
  toggleAddOnSelected: () => {},
  enableAddOnsSelection: true,
});

export const BillingContextProvider: FC<IBillingContextProps> = ({
  billingUserType,
  goBack,
  goNext,
  goBackText,
  checkoutText,
  children,
  checkoutFlow,
  queryAuthParams,
  postCheckoutCallback,
  successCallback,
  navigateToCompletePage,
  onPaymentSuccessGoBackAction,
  disableDiscountCodeField = false,
  planTypeFilterOverride = PlanFilter.Classic,
  isCommonHeader = false,
  customTexts = {},
  isHiddenPeriodToggle = false,
  preselectedPlanOverride,
  preselectedAddOnsOverride,
  planPeriodOverride,
  presetExistingPaymentMethod = false,
  enableAddOnsSelection = true,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [useExistingPaymentMethod, setUseExistingPaymentMethod] =
    useState<boolean>(presetExistingPaymentMethod);
  const [planTypeFilter, setPlanTypeFilter] = useState<PlanFilter>(
    (urlParams.get("planType") as PlanFilter) || planTypeFilterOverride
  );
  const [planPeriodData, setPlanPeriodData] = useState<IPlanPeriodData>({
    selectedPeriod: undefined,
    selectedAddOns: [],
  });

  const toggleAddOnSelected = (addOn: IAddOns) => {
    setPlanPeriodData((prevState) => {
      const updatedAddOns = prevState.selectedAddOns.includes(addOn)
        ? prevState.selectedAddOns.filter((i) => i.code !== addOn.code)
        : [...prevState.selectedAddOns, addOn];

      return {
        ...prevState,
        selectedAddOns: updatedAddOns,
      };
    });
  };

  const planPeriodFilter = urlParams.get("planPeriod") || planPeriodOverride;

  const value = useMemo(
    () => ({
      billingUserType,
      goBack,
      goNext,
      goBackText,
      checkoutText,
      postCheckoutCallback,
      successCallback,
      onPaymentSuccessGoBackAction,
      navigateToCompletePage,
      checkoutFlow,
      planTypeFilter,
      planPeriodFilter,
      setPlanTypeFilter,
      useExistingPaymentMethod,
      setUseExistingPaymentMethod,
      disableDiscountCodeField,
      isCommonHeader,
      customTexts: { ...customDefaultText, ...customTexts },
      isHiddenPeriodToggle,
      preselectedPlanOverride,
      preselectedAddOnsOverride,
      planPeriodData,
      setPlanPeriodData,
      toggleAddOnSelected,
      enableAddOnsSelection,
    }),
    [
      billingUserType,
      goBack,
      goNext,
      postCheckoutCallback,
      successCallback,
      onPaymentSuccessGoBackAction,
      navigateToCompletePage,
      checkoutFlow,
      planTypeFilter,
      planPeriodFilter,
      setPlanTypeFilter,
      useExistingPaymentMethod,
      setUseExistingPaymentMethod,
      disableDiscountCodeField,
      isCommonHeader,
      customTexts,
      isHiddenPeriodToggle,
      preselectedPlanOverride,
      preselectedAddOnsOverride,
      planPeriodData,
      setPlanPeriodData,
      toggleAddOnSelected,
      enableAddOnsSelection,
    ]
  );

  useEffect(() => {
    if (checkoutFlow) {
      billingService.setEndPointPreset(checkoutFlow);
    }
  }, [checkoutFlow]);

  useEffect(() => {
    if (queryAuthParams) {
      billingService.setQueryAuthParams(queryAuthParams);
    }

    return () => {
      billingService.setQueryAuthParams(undefined);
    };
  }, [queryAuthParams]);

  return (
    <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
  );
};
